<template>
  <div class="vip-rules">
    <el-form v-if="isShow" ref="ruleForm" label-width="120px" label-position="top" size="small">
      <el-form-item
        v-for="(item, index) in formData"
        :key="index"
        :label="item.vipLevelName + '：'"
      >
        <div class="gap-form-item">
          1： <el-input-number v-model="item.levelConfigList[0].validPeriod" :min="0" :max="100" />
          {{ item.levelConfigList[0].validPeriodUnit || '年' }} 内会员消费金额达到
          <el-input-number v-model="item.levelConfigList[0].minBaseAmount" :min="0" :max="100000" />
          元，达到当天起计算， {{ item.levelConfigList[0].vipLevelValidPeriod }}
          {{ item.levelConfigList[0].validPeriodUnit || '年' }}有效；
        </div>

        <template v-if="index > 0">
          <div class="gap-form-item">
            2：
            <el-input-number v-model="item.levelConfigList[1].validPeriod" :min="1" :max="100" />
            {{ item.levelConfigList[1].validPeriodUnit || '年' }} 内再次累计消费金额小于
            <el-input-number
              v-model="item.levelConfigList[1].maxBaseAmount"
              :min="1"
              :max="100000"
            />
            元，则第二个会员年度降级为“{{ item.levelConfigList[1].nextVipLevelName }}”
          </div>

          <div class="gap-form-item">
            <!-- 3：
            <el-input-number :min="1" :max="100" v-model="item.levelConfigList[2].validPeriod" />
            {{ item.levelConfigList[2].validPeriodUnit || '年' }}内再次累计消费金额大于
            <el-input-number :min="1" :max="100000" v-model="item.levelConfigList[2].minBaseAmount" /> 元，小于
            <el-input-number :min="1" :max="100000" v-model="item.levelConfigList[2].maxBaseAmount" />
            元，则会员有效期顺延“{{ item.levelConfigList[2].vipLevelValidPeriod }}”
            {{ item.levelConfigList[2].validPeriodUnit || '年' }} -->

            3：
            <el-input-number v-model="item.levelConfigList[2].validPeriod" :min="1" :max="100" />
            {{ item.levelConfigList[2].validPeriodUnit || '年' }}内再次累计消费金额大于等于
            <el-input-number
              v-model="item.levelConfigList[2].minBaseAmount"
              :min="1"
              :max="100000"
            />
            元，则会员有效期顺延“{{ item.levelConfigList[2].vipLevelValidPeriod }}”
            {{ item.levelConfigList[2].validPeriodUnit || '年' }}
          </div>
        </template>
      </el-form-item>

      <el-form-item label="" style="text-align: center">
        <el-button type="primary" @click="submitData">确 定</el-button>
        <el-button style="margin-left: 30px" @click="goback">返 回</el-button>
      </el-form-item>
    </el-form>

    <el-empty v-else :description="errorDescription">
      <el-button type="primary" size="small" @click="goback">返 回</el-button>
    </el-empty>
  </div>
</template>

<script>
import { getVipLevelRules, updateVipLevelRules, checkConfigVipLevelRules } from '@/api/vipLevel.js'

export default {
  data() {
    return {
      formData: [],
      isShow: false,
      errorDescription: '',
    }
  },
  created() {
    this.checkConfigs()
  },
  methods: {
    goback() {
      this.$router.back()
    },
    getData() {
      getVipLevelRules({ data: { isOnShelf: 1 } })
        .then((res) => {
          this.formData = [...(res.data || [])]
        })
        .catch((err) => console.log(err.message || err))
    },
    submitData() {
      // const flag = this.formData.some((el) => {
      //   const res = el.levelConfigList.some((x) => {
      //     if (!x.validPeriod) return true
      //     if (x.configType == 1 && !x.minBaseAmount) return true
      //     if (x.configType == 2 && !x.maxBaseAmount) return true
      //     if (x.configType == 3 && (!x.minBaseAmount || !x.minBaseAmount)) return true
      //   })

      //   return res
      // })

      // if (flag) {
      //   this.$message.warning('请检查配置是否符合要求！')
      //   return
      // }

      updateVipLevelRules({ data: [...this.formData] })
        .then((res) => {
          if (!res.success) return
          this.getData()
          this.$message.success('操作成功')
        })
        .catch((err) => console.log(err.message || err))
    },
    checkConfigs() {
      checkConfigVipLevelRules({ data: {} })
        .then((res) => {
          this.isShow = res.data === 1
          if (res.data === 1) this.getData()
          this.errorDescription = res.description || ''
        })
        .catch((err) => {
          this.errorDescription = err.description || err.message || err
          console.log(err.message || err)
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.vip-rules {
  box-sizing: border-box;
  background-color: #fff;
  padding: 45px;

  .gap-form-item {
    display: block;
    margin-bottom: 15px;
  }
}
</style>
